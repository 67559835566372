
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Grid, Icon, Modal } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import { useEffect, useState } from "react";
import { processHeadersCatalogs, processHeadersCatalogsCsv } from "tools/helpers";
import { CheckBox, EditNote } from "@mui/icons-material";
import { KEY_USER } from "constans";

function Participants( {data, label, setOpen, formData, confirmRegister, setOpenDataParticipant, setDataParticipant, confirmDelete, downloadPDFGafete}) {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(KEY_USER)));
  const [columns, setColumns] = useState([]);
  const [columnsCSV, setColumnsCSV] = useState([]);

    const defaultColumns = [
    {
      name: 'Tipo',
      selector: row => row.type_visitor,
      compact: true
    },
    {
      name: 'Nombre',
      selector: row => <Link size="h5" onClick={()=>downloadPDFGafete(row)} >{row.name}</Link> ,
       width: '250px'
    },
    {
      name: 'Correo',
      selector: row => row.email,
      width: '200px'
    },
    {
    name: 'Teléfono',
    selector: row => row.phone,
    compact: true
    },
    {
      name: 'Profesión',
      selector: row => row.position,
      compact: true
      },
    {
      name: 'Cupón',
      selector: row => row.coupon,
      compact: true
    },
    {
      name: 'Costo',
      selector: row => row.cost,
      compact: true
    },
    {
      name: 'No. Membresía',
      selector: row => row.membership,
      compact: true
    },
  ];

    const columnApprove = [
    {
      name: 'Editar',
      selector: row => <EditNote onClick={()=>{
        setOpenDataParticipant(true);
        setDataParticipant(row);
      }} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</EditNote>,
      compact: true
    },
    {
      name: 'Validar',
      selector: row => <CheckBox onClick={()=>confirmRegister(row)} style={{ cursor: "pointer"}} fontSize="medium" color={`${parseInt(row.status) === 1 ? 'success' : 'default'}`}>delete</CheckBox>,
      compact: true
    },
    {
      name: '',
      selector: row => userData.type === 'admin' ? <Icon  onClick={()=>confirmDelete(row, 'Register')} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</Icon> : null,
      compact: true
      },
  ];

  const defaultColumnsCSV = [
    {key: "type_visitor", label: "Tipo" },
    {key: "name", label: "Nombre" },
    {key: "phone", label: "Teléfono" },
    {key: "email", label: "Correo" },
    {key: "position", label: "Profesión" },
    {key: "coupon", label: "Cupón" },
    {key: "membership", label: "No. membresía" },
    {key: "bussiness_name", label: "Razón social" },
    {key: "rfc", label: "RFC" },
    {key: "address", label: "Dirección fiscal" },
    {key: "cfdi", label: "Uso de CFDI" },
    {key: "regime", label: "Régimen" },
  ];

  const processHeadersData =(headers) =>{
    const newList = processHeadersCatalogs(headers);
    const newListCSV = processHeadersCatalogsCsv(headers);
    setColumns([...defaultColumns, ...newList, ...columnApprove]);
    setColumnsCSV([...defaultColumnsCSV, ...newListCSV]);
  }

    useEffect(()=>{
      processHeadersData(data.headers);
    },[data])
    
    return(
      <SoftBox sx={8} md={12}>
        <Grid container p={1}>
        <Grid container item md={12}>
          <Grid item md={4}>
              <SoftTypography variant="h5" fontSize={18}>{label} </SoftTypography>
          </Grid>
          <Grid item container md={8} justifyContent="flex-end">
            <SoftButton
                md={6}
                variant="gradient"
                color= "primary"
                size="medium"
                style = {{ marginRight: 5, margin: 3 }}
                onClick = {()=>{
                  const domain = window.location.hostname;
                  window.open(`/register/${formData.slug}/1`, '_blank');
                }}
              >
                Registro General
            </SoftButton>
            {/* <SoftButton
                md={6}
                variant="gradient"
                color= "info"
                size="medium"
                style = {{ marginRight: 20,  margin: 3  }}
                onClick = {()=>{
                  const domain = window.location.hostname;
                  window.open(`/register/${formData.slug}/2`, '_blank');
                }}
              >
                Registro Especial
            </SoftButton> */}
            <SoftButton
                md={12}
                variant="gradient"
                color= "success"
                size="medium"
                style = {{ margin: 3  }}
              >
                {
                  data.data ?
                    <CSVLink data={data.data} headers={columnsCSV} filename={`Participantes_${formData.name}.csv`} style={{color: 'white'}}>Descargar Excel</CSVLink>
                  : null
                }
              </SoftButton>
          </Grid>
        </Grid>
        <Grid md={12}>
          <DataTableExtensions
            columns={columns}
            data={data.data}
            export={false}
            print={false}
            filterPlaceholder="Buscar..."
          >
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: 'Registros por página:', 
                rangeSeparatorText: 'de', 
                noRowsPerPage: false, 
                selectAllRowsItem: true, 
                selectAllRowsItemText: 'Todos',
              }}
              paginationPerPage={20}
              noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
            />
          </DataTableExtensions>
        </Grid>
      </Grid>
    </SoftBox>
    )
  
}

export default Participants;